<template>
	<v-layout :class="{'align-center justify-center column mt-4': !isOnCall}" class="ma-3">
		<contact-avatar
			:size="60"
			:contact="patientInfo"
			:anonymous="anonymous"
			:go-to-conversation="true"
		/>
			<div v-if="loadingPatientInfo" class="mt-3">
						<v-progress-circular
							indeterminate
							color="secondary"
						/>
			</div>
			<div :class="isOnCall ? 'pl-4' : ''">
			<span v-if="!loadingPatientInfo" class="text-subtitle-1 font-weight-bold truncate">
				{{ participantLabel }} <span v-if="businessName"> - {{ businessName }} </span>
				</span>

				<span v-show="isOnCall && !durationLoading" class="text-subtitle-1 font-weight-bold">
					{{ callDuration }}
				</span>
				<v-progress-circular
					v-show="durationLoading"
					size="15"
					color="primary"
					indeterminate
				/>
			</div>
		<span v-if="!isOnCall" v-show="participantName || loadingPatientInfo" class="text-subtitle-1 text--secondary font-weight-bold">
				{{ participantPhoneNumber }}
			</span>
		<v-row class="justify-end px-3 my-1">
			<hangUp-button v-if="isOnCall" />
		</v-row>
		</v-layout>
</template>

<script>
import { phoneNumberFormater, fullName } from "@/common/utils";
import { mapGetters } from "vuex";
import ContactAvatar from "../../contact/ContactAvatar.vue";
import { HttpStatusCode } from "axios";
import { ANONYMOUS_CALLERS } from "@/common/const";
import HangUpButton from "./HangUpButton.vue";

export default {
	name: "CallInfo",

	components: {
		ContactAvatar,
		HangUpButton
	},

	data() {
		return {
			patientInfo: undefined,
			loadingPatientInfo: false
		};
	},

	computed: {
		...mapGetters("voice", ["isOnCall", "activeCall", "callDetails"]),

		callDuration() {
			return this.activeCall?.duration;
		},

		participantLabel() {
			if (this.participantName) {
				return fullName(this.patientInfo?.name);
			} else {
				return phoneNumberFormater(this.callDetails?.callerName || this.callDetails?.phoneNumber);
			}
		},

		businessName () {
			return this.patientInfo?.business;
		},

		participantName() {
			return this.patientInfo?.name?.first && this.patientInfo?.name?.last;
		},

		participantPhoneNumber() {
			if (this.callDetails?.callerName && this.callDetails?.callerName !== this.participantLabel) {
				return phoneNumberFormater(this.callDetails?.callerName);
			}

			if (this.callDetails?.phoneNumber) {
				return phoneNumberFormater(this.callDetails?.phoneNumber);
			}

			return phoneNumberFormater(this.callDetails?.callerName);
		},

		loading() {
			return this.callDetails?.loadingPatientInfo;
		},

		durationLoading() {
			return this.activeCall?.duration === "00:00" &&
			this.activeCall?.direction === "outbound";
		},

		anonymous() {
			return ANONYMOUS_CALLERS.includes(this.callDetails?.phoneNumber);
		}
	},

	async mounted() {
		if (this.callDetails) {
			try {
				this.loadingPatientInfo = true;
				const res = await this.axios.get(`patients/${this.callDetails.phoneNumber}`);

				this.patientInfo = res?.data;
			} catch (error) {
				// Ignore error if contact is not found
				if (error.response.status !== HttpStatusCode.NotFound) {
					this.$error(this.$t("error.get-patient"));
				}
			} finally {
				this.loadingPatientInfo = false;
			}
		} else {
			console.error("No call details found");
		}
	}
};
</script>

<style scoped>
.truncate {
	word-wrap: break-word;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	word-break: break-all;
}
</style>
