<template>
	<v-layout column class="py-2 px-2 mb-1">
		<v-card-actions>
			<v-row
				v-if="hasIncomingCall"
				no-gutters
				class="d-flex justify-space-around"
			>
				<dial-button
					:is-inbound="true"
				/>

				<HangUpButton />
			</v-row>

			<v-row v-if="isOnCall" class="d-flex justify-space-between px-3 pb-3">
				<v-tooltip top>
					<template #activator="{ on, attrs }">
						<v-btn
							outlined
							fab
							color="primary"
							v-bind="attrs"
							:disabled="disableForward"
							small
							v-on="on"
							@click="isTransferCallOpen = true"
						>
							<v-icon>
								mdi-arrow-right-bottom
							</v-icon>
						</v-btn>
					</template>

					<span>{{ $t("transfer") }}</span>
				</v-tooltip>

				<v-tooltip top>
					<template #activator="{ on, attrs }">
						<v-btn
							v-bind="attrs"
							outlined
							fab
							color="primary"
							class="button px-3"
							:class="blinkButtonClass"
							:disabled="disableOnHold"
							small
							v-on="on"
							@click="toggleOnHoldCall"
						>
							<v-progress-circular
								v-if="onHoldLoading"
								:width="4"
								color="primary"
								indeterminate
							/>

							<v-icon v-else>
								{{ activeCall && activeCall.onHold ? "mdi-play" : "mdi-pause" }}
							</v-icon>
						</v-btn>
					</template>

					<span>{{ $t(activeCall && activeCall.onHold ? "resume-cta" : "hold-cta") }}</span>
				</v-tooltip>

				<v-tooltip top>
					<template #activator="{ on }">
						<v-btn
							outlined
							fab
							color="primary"
							class="px-3"
							small
							:disabled="!isCallAccepted"
							v-on="on"
							@click="parkCall"
						>
							<v-icon size="md">
								mdi-format-list-bulleted-square
							</v-icon>
						</v-btn>
					</template>

					<span>{{ $t( "send-to-queue") }}</span>
				</v-tooltip>

				<v-tooltip top>
					<template #activator="{ on }">
						<v-btn
							outlined
							fab
							color="primary"
							:disabled="isTransfering"
							class="px-3"
							small
							v-on="on"
							@click="$emit('showDialpad')"
						>
							<v-icon size="md">
								mdi-dialpad
							</v-icon>
						</v-btn>
					</template>

					<span>{{ $t("show-dialpad") }}</span>
				</v-tooltip>
			</v-row>
		</v-card-actions>

		<transfer-call
			v-if="isTransferCallOpen"
			:open="isTransferCallOpen"
			:groups="groups"
			:extensions="extensions"
			@cancel="isTransferCallOpen = false"
			@confirm="transferCall($event)"
		/>
	</v-layout>
</template>

<script>
import {
	mapActions,
	mapGetters,
	mapMutations
} from "vuex";
import { updateConversationStateAttributes } from "@/common/utils";
import { operateHeadset } from "@/common/headset";
import { EnqueueCallException } from "../../../composables/voice/exceptions";
import TransferCall from "../TransferCall.vue";
import DialButton from "./DialButton.vue";
import HangUpButton from "./HangUpButton.vue";

export default {
	name: "CallActions",

	components: {
		TransferCall,
		DialButton,
		HangUpButton
	},

	data() {
		return {
			enableOnHoldButton: false,
			isTransferCallOpen: false,
			onHoldLoading: false,
			showDialpad: false,
			transferStarted: false,
			groups: null,
			extensions: null
		};
	},

	computed: {
		...mapGetters("voice", [
			"isOnCall",
			"hasIncomingCall",
			"callDetails",
			"activeCall",
			"isCallAccepted"
		]),

		...mapGetters("conversation", ["currentParticipant"]),
		...mapGetters("clinic", ["clinicData", "enableExtTransfer"]),

		blinkButtonClass() {
			return this.activeCall?.onHold && !this.onHoldLoading ? "blink" : "";
		},

		disableOnHold() {
			// TODO: find a way to disable while callee has not answered yet
			return !this.enableOnHoldButton || this.onHoldLoading || this.transferStarted;
		},

		isTransfering() {
			return this.transferStarted;
		},

		disableForward() {
			// TODO: find a way to disable while callee has not answered yet
			return !this.isCallAccepted || this.isTransfering;
		}
	},

	watch: {
		isCallAccepted: {
			immediate: true,

			handler(isCallAccepted) {
				this.enableOnHoldButton = isCallAccepted;
			}
		},

		isTransferCallOpen: {
			async handler(val) {
				if (val) {
					this.getTransferData();
				}
			}
		}
	},

	methods: {
		...mapActions("conversation", ["displayConversation"]),
		...mapActions("groups", ["getAllGroups"]),

		...mapMutations("voice", [
			"setIsMicroGuideOpen",
			"setIsMicroOn",
			"enableMicrophone",
			"disableMicrophone"
		]),

		async getTransferData() {
			try {
				const { data } = await this.axios.get("voice/v1/calls/availability");

				// Data received is an array of array.
				this.groups = data.flat();

				if (this.enableExtTransfer) {
					this.extensions = (await this.axios.get("transfer-extension")).data;
				}

			} catch (error) {
				console.error(error);
				this.$error(this.$t("error.get-groups"));
			}
		},

		async toggleOnHoldCall() {
			this.onHoldLoading = true;
			try {
				if (this.activeCall?.onHold) {
					operateHeadset(true, false, false, false);
					await this.activeCall.resume();
				} else {
					operateHeadset(false, false, false, true);
					await this.activeCall.hold();
				};
			} catch (err) {
				this.$error(err instanceof EnqueueCallException
					? this.$t("error.enqueue")
					: this.$t("error.dequeue"));
			} finally {
				this.onHoldLoading = false;
			}
		},

		async findGroupFromPhone(phoneNumber) {
			const allGroups = await this.getAllGroups();

			return allGroups.find((group) => group.phoneNumber === phoneNumber);
		},

		async toggleConversationStatus(sid) {
			try {
				await updateConversationStateAttributes(sid, {
					state: "active"
				});

			} catch (err) {
				this.$error(this.$t("error.update-status"));
			}
		},

		async transferCall({ group, userClient, userSip, extensionUser }) {
			let checkCallStatusInterval;

			this.transferStarted = true;
			this.$emit("transfering");
			try {
				await this.activeCall.transferCall(group, userClient, userSip, extensionUser);
				checkCallStatusInterval = setInterval(() => {
					const status = this.activeCall?.callInstance?.status();

					// Waiting for other user to pick up
					if (status === "ringing" || status === "open") {
						clearInterval(checkCallStatusInterval);
					} else {
						clearInterval(checkCallStatusInterval);
						this.transferStarted = false;
						return;
					}
				}, 1000);
			} catch (err) {
				clearInterval(checkCallStatusInterval);
				this.transferStarted = false;
			} finally {
				this.transferStarted = false;
				this.isTransferCallOpen = false;
				this.$emit("transfering");
			}
		},

		async parkCall() {
			await this.activeCall.parkCall();
		}
	}
};
</script>

<style scoped>
.blink {
	animation: blinking ease-out 1s infinite;
}

@keyframes blinking {
	0% {
		background-color: var(--v-primary-base);
	}

	100% {
		background-color: #fff;
	}
}
</style>
