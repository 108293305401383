import PhoneCall from "./phone-call";
import axios from "axios";

class OutboundCall extends PhoneCall {
	constructor(callInstance, parameters) {
		super(callInstance, false, parameters);
		this.direction = "outbound";
	}

	start = () => {
		const durationInt = setInterval(() => {
			const status = this.callInstance?.status();

			if (status === "ringing" || status === "open") {
				clearInterval(durationInt);
				this.countDuration();
			} else if (status === "closed") {
				clearInterval(durationInt);
			}
		}, 1000);

		const checkStatus = async () => {
			const status = this.callInstance?.status();

			if (status === "ringing" || status === "open") {
				try {
					const { data: call } = await axios.get(`/voice/v1/calls/sid/${this.callSid}`);

					if (call?.answeredAt) {
						this.callDetails.id = call.id;
						this.answered();
						return;
					}
				} catch (err) {}
			} else if (status === "closed") {
				this.answered();
				return;
			}

			setTimeout(checkStatus, 1000);
		};

		checkStatus();
	};

	static from(callInstance, parameters) {
		return new OutboundCall(callInstance, parameters);
	}
}

export default OutboundCall;
