import router from "../router";
import Vuetify from "../plugins/vuetify";
import { getField, updateField } from "vuex-map-fields";

import {
	ALERT_HIDDEN
} from "@/common/const";

export default {
	namespaced: true,
	state: () => ({
		ready: false,
		enabled: false,
		incomingCall: false,
		isOnCall: false,
		isMicroGuideOpen: false,
		isCallDialogAttached: false,
		activeCall: null,
		callDetails: null,
		isCallAccepted: false,
		isMicroOn: true,
		localStream: null,
		headsetDevice: null,
		headsetDeviceReportId: null,
		activeCallConvoSid: undefined,
		activeCallConvoState: undefined,
		draggableIsMoving: false,
		digitsPressed: null,
		showIncomingCallAlert: ALERT_HIDDEN
	}),

	getters: {
		getField,
		isReady: (state) => state.ready,
		hasIncomingCall: (state) => state.incomingCall,
		isOnCall: (state) => state.isOnCall,
		callDetails : (state) => state.callDetails,
		isMicroGuideOpen: (state) => state.isMicroGuideOpen,
		isCallDialogAttached: (state) => {
			const isConversations = router.app.$route.path == "/conversations";

			if (isConversations && (state.isOnCall || state.incomingCall || Vuetify.framework.breakpoint.smAndDown)) return state.isCallDialogAttached;
			return state.isCallDialogAttached = isConversations;
		},
		activeCall: (state) => state.activeCall,
		isCallAccepted: (state) => state.isCallAccepted,
		isMicroOn: (state) => state.isMicroOn,
		localStream: (state) => state.localStream,
		headsetDevice: (state) => state.headsetDevice,
		headsetDeviceReportId: (state) => state.headsetDeviceReportId,
		activeCallConvoSid: (state) => state.activeCallConvoSid,
		activeCallConvoState: (state) => state.activeCallConvoState,
		draggableIsMoving: (state) => state.draggableIsMoving,
		digitsPressed: (state) => state.digitsPressed
	},

	mutations: {
		updateField,
		setReady: (state, isReady) => state.ready = isReady,
		setInboundCall: (state, callInstance) => {
			if (!state.incomingCall && !state.isOnCall) {
				state.activeCall = callInstance;
				state.incomingCall = true;
				state.isOnCall = false;
				state.isCallAccepted = false;
			}
		},
		setInboundQueueCall: (state, callInstance) => {
			state.activeCall = callInstance;
			state.incomingCall = false;
			state.isOnCall = true;
			state.isCallAccepted = true;
		},
		setOutboundCall: (state, callInstance) => {
			state.activeCall = callInstance;
			state.incomingCall = false;
			state.isOnCall = true;
			state.isCallAccepted = false;
		},
		setCallDetails: (state, callDetails) => {
			console.debug("Call details ", callDetails);
			state.callDetails = callDetails;
		},
		setIsMicroGuideOpen: (state, isMicroGuideOpen) => state.isMicroGuideOpen = isMicroGuideOpen,
		setCallDialogAttached: (state, isCallDialogAttached) => state.isCallDialogAttached = isCallDialogAttached,
		setIsOnCall: (state, isOnCall) => state.isOnCall = isOnCall,
		setIsCallAccepted: (state, isCallAccepted) => {
			state.isCallAccepted = isCallAccepted;
		},
		setIsMicroOn: (state, isMicroOn) => state.isMicroOn = isMicroOn,
		enableMicrophone: (state) => {
			state.activeCall?.mute(false);
			navigator.mediaDevices
				.getUserMedia({
					audio: true
				})
				.then((stream) => {
					state.localStream = stream;
				});
		},

		setHeadsetDevice: (state, device) => {
			state.headsetDevice = device;
		},

		setHeadsetDeviceReportId: (state, reportId) => {
			state.headsetDeviceReportId = reportId;
		},

		disableMicrophone: (state) => {
			state.activeCall?.mute(true);
			state.localStream?.getTracks().forEach((track) => {
				track.stop();
			});
		},

		setDraggableIsMoving: (state, draggableIsMoving) => state.draggableIsMoving = draggableIsMoving,
		// Sets new digits to display and send them to active call instance
		setDigitsPressed: (state, digit) => {
			state.activeCall?.callInstance?.sendDigits(digit);
			if (state.digitsPressed) {
				state.digitsPressed += digit;
			} else {
				state.digitsPressed = digit;
			}
		},

		reset: (state) => {
			if (state.activeCall) {
				if (state.incomingCall) {
					state.activeCall.reject();
				} else {
					state.activeCall.hangUp();
				}
			}

			state.ready = false;
			state.enabled = false;
			state.incomingCall = false;
			state.isOnCall = false;
			state.isMicroGuideOpen = false;
			state.isCallDialogAttached = false;
			state.draggableIsMoving = false;
			state.isCallAccepted = false;
			state.isMicroOn = (state.isMicroOn !== false);

			state.activeCall= null;
			state.callDetails = null;
			state.digitsPressed = null;
			state.localStream = null;
			state.activeCallConvoSid = undefined;
			state.activeCallConvoState = undefined;
		},

		clearDigitsPressed: (state) => {
			state.digitsPressed = null;
		},

		acceptCall: (state) => {
			if (state.activeCall) {
				state.incomingCall = false;
				state.isCallAccepted = true;
				state.isOnCall = true;
			}
		},

		disconnectCall: (state) => {
			if (state.incomingCall || state.isOnCall) {
				state.incomingCall = false;
				state.isOnCall = false;
				state.isCallAccepted = false;
				state.activeCall = null;
				state.callDetails = null;
			}
		}
	},

	actions: {
		setMicrophoneDialog({ commit }, newValue) {
			commit("SET_MICROPHONE_DIALOG", newValue);
		},

		async endCall({ state, commit }) {
			if (state.callDetails?.id) {
				await commit("queue/releaseCall", state.callDetails.id, { root: true });
			}

			commit("disconnectCall");
		}
	}
};
