import { render, staticRenderFns } from "./CallActions.vue?vue&type=template&id=c13b0358&scoped=true"
import script from "./CallActions.vue?vue&type=script&lang=js"
export * from "./CallActions.vue?vue&type=script&lang=js"
import style0 from "./CallActions.vue?vue&type=style&index=0&id=c13b0358&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c13b0358",
  null
  
)

export default component.exports