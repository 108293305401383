import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import { ApmVuePlugin } from "@elastic/apm-rum-vue";

Vue.use(VueRouter);

// Handle navigation duplication for router push (Globally)
const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((error) => {});
};

// Setup routes
const routes = [
	{
		path: "/onboarding",
		name: "onboarding",
		component: () => import(/* webpackChunkName: "onboarding" */ "@/views/Onboarding.vue"),
		meta: {
			menu: false
		}
	}, {
		path: "/login/:lang(en)?", // Will match locale in the (....). Add more: (fr | en | de)
		name: "login",
		component: () => import(/* webpackChunkName: "login" */ "@/views/Login.vue"),
		meta: {
			nav: false,
			menu: false
		}
	}, {
		path: "/password-forgot",
		component: () => import(/* webpackChunkName: "password-forgot" */ "@/views/ForgotPassword.vue"),
		meta: {
			nav: false,
			menu: false
		}
	}, {
		path: "/password-reset",
		component: () => import(/* webpackChunkName: "password-reset" */ "@/views/ResetPassword.vue"),
		meta: {
			nav: false,
			menu: false
		}
	}, {
		path: "/secure",
		component: () => import(/* webpackChunkName: "secure-file" */ "@/views/SecureFile.vue"),
		meta: {
			nav: false,
			menu: false
		}
	}, {
		path: "/send-file",
		component: () => import(/* webpackChunkName: "send-file" */ "@/views/SendFile.vue"),
		meta: {
			nav: false,
			menu: false
		}
	}, {
		path: "/devops",
		name: "devops",
		component: () => import(/* webpackChunkName: "devops" */ "@/views/Devops.vue"),
		meta: {
			requiresAuth: true
		}
	}, {
		path: "/configuration",
		name: "configuration",
		component: () => import(/* webpackChunkName: "configuration" */ "@/views/Configuration.vue"),
		meta: {
			requiresAuth: true
		}
	}, {
		path: "/personnalisation",
		component: () => import(/* webpackChunkName: "personnalisation" */ "@/views/Personalization.vue"),
		meta: {
			requiresAuth: true
		}
	}, {
		path: "/contacts",
		name: "contacts",
		component: () => import(/* webpackChunkName: "contacts" */ "@/components/contacts/Contacts.vue"),
		meta: {
			requiresAuth: true
		}
	}, {
		path: "/members",
		component: () => import(/* webpackChunkName: "dentistes" */ "@/views/Dentists.vue"),
		meta: {
			requiresAuth: true
		}
	}, {
		path: "/members/:id?/availabilities",
		component: () => import(/* webpackChunkName: "availabilities" */ "@/views/Availabilities.vue"),
		meta: {
			requiresAuth: true
		}
	}, {
		path: "/conversations",
		name: "conversations",
		component: () => import(/* webpackChunkName: "conversations" */ "@/views/Conversations.vue"),
		meta: {
			requiresAuth: true
		}
	}, {
		path: "/material/:type",
		component: () => import(/* webpackChunkName: "material" */ "@/views/Material.vue"),
		meta: {
			requiresAuth: true
		}
	}, {
		path: "/text-bloc",
		component: () => import(/* webpackChunkName: "text-bloc" */ "@/views/TextBloc.vue"),
		meta: {
			requiresAuth: true
		}
	}, {
		path: "/text-template/:mode/:id?",
		component: () => import(/* webpackChunkName: "text-template" */ "@/views/TextTemplate.vue"),
		meta: {
			requiresAuth: true
		}
	}, {
		path: "/consultations/:id?",
		component: () => import(/* webpackChunkName: "consultations" */ "@/views/Consultations.vue"),
		meta: {
			requiresAuth: true,
			nav: false,
			menu: false
		}
	}, {
		path: "/content-management/:operation/:type/:id?",
		component: () => import(/* webpackChunkName: "content-management" */ "@/views/ContentManagement.vue"),
		meta: {
			requiresAuth: true,
			nav: false,
			menu: false
		}
	}, {
		path: "/release-notes",
		component: () => import(/* webpackChunkName: "release-notes" */ "@/views/ReleaseNotes.vue"),
		meta: {
			requiresAuth: true
		}
	}, {
		path: "/agenda",
		component: () => import(/* webpackChunkName: "agenda" */ "@/views/Agenda.vue"),
		meta: {
			requiresAuth: true
		}
	}, {
		path: "/verification/error/:provider",
		component: () => import(/* webpackChunkName: "authorizationFailed" */ "@/views/AuthorizationFailed.vue"),
		meta: {
			requiresAuth: false,
			nav: false,
			menu: false
		}
	}, {
		path: "/verification/success",
		component: () => import(/* webpackChunkName: "authorizationSuccess" */ "@/views/AuthorizationSuccess.vue"),
		meta: {
			requiresAuth: false,
			nav: false,
			menu: false
		}
	}, {
		path: "*",
		redirect: "/login"
	}, {
		path: "/faq",
		name: "FAQ",
		component: () => import(/* webpackChunkName: "dashboard" */ "@/views/FAQ.vue"),
		meta: {
			requiresAuth: true
		}
	}
];

// Create the router
const router = new VueRouter({
	base: process.env.BASE_URL,
	mode: "history",
	routes
});

Vue.use(ApmVuePlugin, {
	router,
	config: {
		serviceName: "fresk-application",
		serverUrl: "https://apm.plogg.in:8200",
		environment: process.env.NODE_ENV
	}
});

// Router callbacks
router.beforeEach(async (to, _, next) => {
	store.commit(to.meta.nav === false ? "ui/disableNavigation" : "ui/enableNavigation");
	store.commit(to.meta.menu === false ? "ui/disableMenu" : "ui/enableMenu");

	next();
});

router.beforeEach(async (to, from, next) => {
	if (to.name !== "widget") {
		store.dispatch("login/activity");
	}

	if (to.matched.some((record) => record.meta.requiresAuth)) {
		if (!store.state.login.jwt) {
			next({
				path: "/login"
			});
		} else {
			next();
		}
	} else {
		next();
	}

	store.commit("alerts/clear");
});

router.beforeEach(async (to, from, next) => {
	next();
});

export default router;
