<template>
	<v-tooltip top :disabled="isInbound && isHavingCall">
			<template #activator="{ on }">
				<div v-on="on">
					<v-btn
						fab
						elevation="0"
						color="primary"
						:large="!isInbound"
						:small="isInbound"
						:disabled="isDisabledByPermission || !isCallAvailable"
						:outlined="!isCallAvailable"
						@click="handleCall"
					>
						<v-icon color="white"> {{ icon }} </v-icon>
					</v-btn>
				</div>
			</template>

		<span class="wrapped-text">{{ buttonText }}</span>
	</v-tooltip>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import useVoice from "@/composables/voice";
import useConversation from "@/composables/conversation";
import { getMicrophoneStatus } from "@/common/utils";

const conversationsHook = useConversation();

export default {
	name: "DialButton",

	props: {
		isCallAvailable: {
			type: Boolean,
			default: true
		},

		isHavingCall: {
			type: Boolean,
			default: false
		},

		isInbound: {
			type: Boolean,
			default: false
		}
	},

	computed: {
		...mapGetters("conversation", ["patient", "singleConversation", "currentParticipant"]),
		...mapGetters("voice", ["activeCall", "isMicroOn", "callDetails"]),
		...mapGetters("clinic", ["enableOutgoingCalls"]),

		buttonText() {
			if (!this.isMicroOn) {
				return this.$t("no-microphone");
			}

			if (this.isHavingCall && !this.isCallAvailable) {
				return this.$t("call-in-progress");
			}

			if (this.isInbound) {
				return this.$t("answer");
			}

			if (!this.enableOutgoingCalls) {
				return this.$t("call-disabled-by-permission");
			}

			return this.$t(this.isCallAvailable ? "call-contact" : "call-disabled");
		},

		isDisabledByPermission() {
			return !(this.isInbound || this.enableOutgoingCalls);
		},

		icon() {
			return !this.isMicroOn ? "mdi-phone-alert" : this.isInbound ? "mdi-phone" : "mdi-phone-in-talk";
		}
	},

	methods: {
		...mapMutations("voice", ["setIsMicroGuideOpen"]),

		async callContact() {
			document.activeElement.blur();
			try {
				const isMicrophonePermissionGranted = await getMicrophoneStatus();

				if (isMicrophonePermissionGranted) {
					await this.outboundCall();
				} else {
					await this.getLocalStream();
				}
			} catch (err) {
				console.error(err);
			}
		},

		async handleAccept() {
			// Clear any digits from the dialpad before answer
			this.$store.commit("voice/clearDigitsPressed");
			try {
				const isMicrophonePermissionGranted = await getMicrophoneStatus();

				if (!isMicrophonePermissionGranted) {
					this.setIsMicroGuideOpen(true);
				} else {
					await this.activeCall.accept();
					const { forGroupId, forGroupLabel } = this.callDetails;

					if (forGroupId && forGroupId !== this.currentParticipant.identity) {
						this.$success(this.$t("changing-group", { label: forGroupLabel }));

						console.debug("ACCEPT USER GROUP:", forGroupId);

						await conversationsHook.bootstrap(forGroupId);
						this.$store.commit("conversation/setCurrentGroup", forGroupId);
					}

					if (this.activeCallConvoSid) {
						this.displayConversation(this.activeCallConvoSid);
					}
				}
			} catch (err) {
				console.error(err);
			}
		},

		async handleCall() {
			return this.isInbound ? this.handleAccept() : this.callContact();
		},

		async getLocalStream() {
			navigator.mediaDevices
				.getUserMedia({ video: false, audio: true })
				.then(async (stream) => {
					await this.outboundCall();
				})
				.catch((err) => {
					this.setIsMicroGuideOpen(true);
				});
		},

		async outboundCall() {
			const voiceId = this.patient?.id || this.singleConversation?.friendlyName;

			if (voiceId) {
				await useVoice.outboundCall(voiceId, this.hasPatient);
			} else {
				throw new Error(this.$t("error.call-contact"));
			}
		}
	}
};
</script>
<style scoped>

.wrapped-text {
	display: inline-block;
	white-space: normal;
	word-break: break-word;
	max-width: 200px;
}

</style>
